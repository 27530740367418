import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlag, User } from 'configcat-react';

const QAPrivatePage: React.FC = () => {
  const navigate = useNavigate();

  const savedEmail = localStorage.getItem('AptiveQA') || '';
  const [email, setEmail] = useState<string>(savedEmail);

  const userObject = new User(email);

  const { value: isQAPageEnabled, loading: isQAPageEnabledLoading } = useFeatureFlag('isQAPageEnabled', false);
  const { value: TestConditionalFlag, loading: TestConditionalFlagLoading } = useFeatureFlag('TestConditionalFlag', false, userObject);
  const { value: useLegacyAuth, loading: useLegacyAuthLoading } = useFeatureFlag('useLegacyAuth', false, userObject);
  const { value: maintenanceMode, loading: maintenanceModeLoading } = useFeatureFlag('maintenanceMode', false, userObject);


  useEffect(() => {
    if (!isQAPageEnabledLoading && isQAPageEnabled === false) {
      navigate('/');
    }
  }, [isQAPageEnabled, isQAPageEnabledLoading, navigate]);


  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };


  const handleSaveEmail = () => {
    localStorage.setItem('AptiveQA', email);
    // eslint-disable-next-line
    console.log(`Email ${email} saved to localStorage as AptiveQA.`);
  };

  const handleClearEmail = () => {
    localStorage.removeItem('AptiveQA');
    setEmail('');
    // eslint-disable-next-line
    console.log('AptiveQA email cleared from localStorage.');
  };

  return (
    <div className="flex w-full">
      <div className="flex w-full">
        <div className="flex justify-center lg:justify-end w-full lg:w-1/2 pb-20 lg:pr-12 xl:pr-32">
          <div className="flex flex-col md:w-[450px] w-full md:pt-32 lg:pl-4 px-4 pt-20">
            <h2>Enter your email to enable feature flags</h2>

            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
              className="border border-gray-300 p-2 mb-4"
            />

            <button
              onClick={handleSaveEmail}
              className="w-full py-2.5 mt-3 text-white bg-aptive-900 hover:bg-aptive-800 disabled:bg-gray-50 disabled:border-none disabled:text-gray-400 pl-6 pr-6 border text-sm rounded shadow-sm block text-center"
            >
              Save Email
            </button>

            <button
              onClick={handleClearEmail}
              className="w-full py-2.5 mt-3 text-white bg-aptive-600 hover:bg-aptive-800 disabled:bg-gray-50 disabled:border-none disabled:text-gray-400 pl-6 pr-6 border text-sm rounded shadow-sm block text-center"
            >
              Clear Email
            </button>

            <div className="mt-6"><strong>Current User:</strong> {savedEmail}</div>
            <div className="mt-6">TestConditionalFlag: {String(TestConditionalFlag)}</div>
            <div className="mt-6">useLegacyAuth: {String(useLegacyAuth)}</div>
            <div className="mt-6">maintenanceMode: {String(maintenanceMode)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QAPrivatePage;
